import Header from "@/components/Header";
import "@/styles/global.scss";
import "antd/es/style/themes/default.less";
import "swiper/css";
import "swiper/css/pagination";
import type {AppProps} from "next/app";
import React, {useEffect, useState} from "react";
import {Router, useRouter} from "next/router";
import {SessionProvider, useSession} from "next-auth/react";
import Footer from "@/components/Footer";
const CustomToast = dynamic(() => import("@/components/CustomToast"), {
  ssr: false,
});

import {QueryClient, QueryClientProvider} from "react-query";
import "nprogress/nprogress.css"; //styles of nprogress
import NProgress from "nprogress";
import dynamic from "next/dynamic";
import HeadSeo from "@/components/HeadSeo";
import defaultMeta from "../public/json/meta/default-meta.json";
import {GoogleOAuthProvider} from "@react-oauth/google";
import RestrictCountryModal from "@/components/RestrictCountryModal";
import uiStore from "@/store/uiStore";
// import GoogleAnalytic from "@/components/GoogleAnalytic";
// import CookieBanner from "@/components/CookieBanner";
const GoogleAnalytic = dynamic(() => import("@/components/GoogleAnalytic"), {
  ssr: false,
});
const CookieBanner = dynamic(() => import("@/components/CookieBanner"), {
  ssr: false,
});

NProgress.configure({showSpinner: false});
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

export default function App({Component, pageProps}: AppProps) {
  const router = useRouter();
  const ui = uiStore();
  const queryClient = new QueryClient({defaultOptions: {queries: {refetchOnWindowFocus: false}}});
  const [showGoogleAnalytic, setShowGoogleAnalytic] = useState(false);

  useEffect(() => {
    const headerElement = document.querySelector(".navbar");
    if (router.asPath !== "/") {
      headerElement?.classList.remove("white-text");
    }
  }, [router.asPath]);

  useEffect(() => {
    if (process.env.DEBUG_ENV !== undefined && process.env.DEBUG_ENV !== "development") {
      setTimeout(() => {
        setShowGoogleAnalytic(true);
      }, 500);
    }
  }, []);

  return (
    <SessionProvider refetchOnWindowFocus={false} session={pageProps.session}>
      {showGoogleAnalytic ? <GoogleAnalytic GA_MEASUREMENT_ID={process.env.NEXT_PUBLIC_GOOGLE_TAG_ID || ""} /> : null}
      <CookieBanner />

      <QueryClientProvider client={queryClient}>
        <HeadSeo
          title={pageProps?.meta?.title ? pageProps.meta?.title : defaultMeta.home.title}
          description={pageProps?.meta?.description ? pageProps?.meta?.description : defaultMeta.home.description}
          canonicalUrl={pageProps?.meta?.url ? pageProps?.meta?.url : process.env.NEXT_PUBLIC_SELF_DOMAIN}
          ogTwitterImage={
            pageProps?.meta?.image
              ? pageProps?.meta?.image
              : `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/images/default-meta.jpg`
          }
          ogImageUrl={
            pageProps?.meta?.image
              ? pageProps?.meta?.image
              : `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/images/default-meta.jpg`
          }
        />

        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CREDENTIAL || ""}>
          <Header />
          <CustomToast />
          <main>
            <Component {...pageProps} />
            <RestrictCountryModal open={ui.openRestrictionCountry} setOpen={ui.setRestrictionCountry} />
            {/* <WaFloating /> */}
          </main>
          <footer className="z-[52] relative">
            <Footer />
          </footer>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </SessionProvider>
  );
}
