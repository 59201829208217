import onError from "@/helper/onError";
import ApiService from "@/libs/ApiServices";
import Api from "@/libs/axios-interceptor";
import {ICartItem} from "@/types/product.types";
import {getSession} from "next-auth/react";
import {toast} from "react-hot-toast";
import {create} from "zustand";

export interface ICartPayload {
  cart_id?: number;
  product_id: number;
  qty: number;
  product_variant_combination_id?: number;
}

interface ICartUserResponse {
  carts: ICartItem[] | [];
  subtotal: string;
  isLoading: boolean;
}

interface ICartStore extends ICartUserResponse {
  getUserCart: () => void;
  addUpdateUserCart: (payload: ICartPayload) => void;
  removeUserCart: (payload: {ids: number[]}) => void;
}

const userCartStore = create<ICartStore>()((set, get) => ({
  carts: [],
  subtotal: "0",
  isLoading: true,

  getUserCart: async () => {
    try {
      const session = await getSession();

      if (!session) {
        set({carts: [], subtotal: "0", isLoading: false});
        return;
      } else {
        // set loading
        set((state) => ({...state, isLoading: true}));

        const response = await ApiService.secure({organization: true}).get("/cart?bought=0");
        if (response.data.status !== 200) {
          set({carts: [], subtotal: "0"});
          throw new Error(response.data.message);
        }

        const product_response: ICartItem[] = response.data.data;
        set({subtotal: response.data.pages.subtotal, carts: product_response});
      }

      // set loading
      set((state) => ({...state, isLoading: false}));
    } catch (error: any) {
      set({carts: [], subtotal: "0"});
      // set loading
      set((state) => ({...state, isLoading: false}));
      onError(error as Error);
    }
  },
  addUpdateUserCart: async (payload) => {
    try {
      // set loading
      set((state) => ({...state, isLoading: true}));

      const response = await ApiService.secure({organization: true}).post("/cart", payload);
      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }
      // set loading
      get().getUserCart();
      // set((state) => ({...state, isLoading: false}));
    } catch (error) {
      // set loading
      set((state) => ({...state, isLoading: false}));
      onError(error as Error);
    }
  },
  removeUserCart: async (payload) => {
    try {
      // set loading
      set((state) => ({...state, isLoading: true}));
      const response = await ApiService.secure({organization: true}).delete("/cart", payload);
      if (response.data.status !== 200) {
        throw new Error(response.data.message);
      }

      // set loading
      set((state) => ({...state, isLoading: false}));
      get().getUserCart();
    } catch (error) {
      // set loading
      set((state) => ({...state, isLoading: false}));
      onError(error as Error);
    }
  },
}));

export default userCartStore;
